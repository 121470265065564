export default [
  {
    path: '/partner',
    component: () => import(/* webpackChunkName: "partner" */ '@/views/partner-dashboard/RjPartnerDashboard.vue'),
    children: [
      {
        path: '',
        name: 'partner-home',
        component: () => import(/* webpackChunkName: "partner" */ '@/views/partner-dashboard/RjHome.vue'),
      },
      {
        path: 'companies',
        name: 'partner-companies',
        component: () => import(/* webpackChunkName: "partner" */ '@/views/partner-dashboard/companies/RjCompanies.vue'),
      },
      {
        path: 'companies/:company',
        component: () => import(/* webpackChunkName: "partner" */ '@/views/partner-dashboard/company/RjBaseCompanyView.vue'),
        children: [
          {
            path: ':slug?',
            name: 'partner-company',
            component: () => import(/* webpackChunkName: "partner" */ '@/views/partner-dashboard/company/RjCompany.vue'),
            props: (route) => ({ slug: route.params.slug || null }),
          },
          {
            path: 'locations/:location',
            name: 'partner-locations-settings',
            component: () => import(/* webpackChunkName: "partner" */ '@/views/partner-dashboard/locations/RjLocationSettings.vue'),
          },
          {
            path: 'funnels/:funnel',
            name: 'partner-funnel-settings',
            component: () => import(/* webpackChunkName: "partner" */ '@/views/partner-dashboard/funnels/RjFunnelSettings.vue'),
          },
        ],
      },
      {
        path: 'accounting',
        name: 'partner-accounting',
        component: () => import(/* webpackChunkName: "partner" */ '@/views/partner-dashboard/subscription/RjAccounting.vue'),
      },
      {
        path: 'settings',
        name: 'partner-settings',
        component: () => import(/* webpackChunkName: "partner" */ '@/views/partner-dashboard/settings/RjSettings.vue'),
      },
      {
        path: 'support',
        name: 'partner-help-support',
        component: () => import(/* webpackChunkName: "partner" */ '@/views/partner-dashboard/help/RjSupport.vue'),
      },
      {
        path: 'notifications',
        name: 'partner-notifications',
        component: () => import(/* webpackChunkName: "partner" */ '@/views/common/notifications/RjNotifications.vue'),
      },
    ],
  },
  {
    path: '/partner/:partner/invoices/:invoice',
    name: 'partner-invoice',
    component: () => import(/* webpackChunkName: "partner" */ '@/views/partner-dashboard/subscription/RjInvoice.vue'),
  },
  {
    path: '/partner/:partner/commission-statement/:statement',
    name: 'partner-commission-statement',
    component: () => import(/* webpackChunkName: "partner" */ '@/views/partner-dashboard/subscription/RjCommissionStatement.vue'),
  },
];
