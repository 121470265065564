import { rjky } from '@/ky';
import { tap } from '@/utils';
import { HIGH_JUMP_CODE, LOW_JUMP_CODE } from '@/rj_consts';

import * as types from '@/store/modules/partners/mutation_types';
import * as usersTypes from '@/store/modules/users/mutation_types';
import * as companyTypes from '@/store/modules/companies/mutation_types';

export default {

  async addHighJump({ commit, getters }, { companyId }) {
    commit(types.UNSET_ERROR);
    const partnerId = getters.partner.id;
    if (!partnerId) {
      return null;
    }
    try {
      await rjky.get(`partners/${partnerId}/addHighJump?companyId=${companyId}`);
      return true;
    } catch (error) {
      const response = await error.response.json();
      commit(types.SET_ERROR, response.message);
      return null;
    }
  },

  async addLowJump({ commit, getters }) {
    commit(types.UNSET_ERROR);
    const partnerId = getters.partner.id;
    if (!partnerId) {
      return null;
    }
    try {
      await rjky.get(`partners/${partnerId}/addLowJump`);
      return true;
    } catch (error) {
      const response = await error.response.json();
      commit(types.SET_ERROR, response.message);
      return null;
    }
  },

  async addAddOn({ commit, getters }, { code, quantity, companyId }) {
    commit(types.UNSET_ERROR);
    const partnerId = getters.partner.id;
    if (!partnerId) {
      return null;
    }
    try {
      return rjky.post(`partners/${partnerId}/addAddOn`, { json: { code, quantity, companyId } });
    } catch (error) {
      return error.response.json();
    }
  },

  async removeAddOn({ commit, getters }, { code, quantity, companyId }) {
    commit(types.UNSET_ERROR);
    const partnerId = getters.partner.id;
    if (!partnerId) {
      return null;
    }
    try {
      return rjky.post(`partners/${partnerId}/removeAddOn`, { json: { code, quantity, companyId } });
    } catch (error) {
      return error.response.json();
    }
  },

  async createNewCompanySkeleton({ commit, getters }, company) {
    commit(types.UNSET_ERROR);
    const partnerId = getters.partner.id;
    if (!partnerId) {
      return null;
    }
    try {
      const {
        companyId,
        locationId,
        userId,
      } = await rjky.post(`partners/${partnerId}/createSkeletonForCertifiedPartner`, { json: { data: company } }).json();
      return { companyId, locationId, userId };
    } catch (error) {
      const response = await error.response.json();
      commit(types.SET_ERROR, response.message);
      throw new Error(response.message);
    }
  },

  async getInvoice({ commit }, { partnerId, invoiceId }) {
    commit(types.UNSET_ERROR);
    try {
      return rjky.get(`partners/${partnerId}/invoices/${invoiceId}`).json();
    } catch (error) {
      commit(types.SET_ERROR, error);
      return [];
    }
  },

  async getInvoices({ commit, getters }) {
    commit(types.UNSET_ERROR);
    try {
      const partnerId = getters.partner.id;
      if (!partnerId) {
        throw new Error('Missing partner');
      }
      return rjky.get(`partners/${partnerId}/invoices`).json();
    } catch (error) {
      commit(types.SET_ERROR, error);
      return [];
    }
  },

  async getUpcomingInvoice({ commit, getters }) {
    commit(types.UNSET_ERROR);
    const partnerId = getters.partner.id;
    try {
      if (!partnerId) {
        throw new Error('Missing partner');
      }
      return rjky.get(`partners/${partnerId}/upcomingInvoice`).json();
    } catch (error) {
      commit(types.SET_ERROR, error);
      return null;
    }
  },

  async getCommissionStatement({ commit }, { partnerId, commissionStatementId }) {
    commit(types.UNSET_ERROR);
    try {
      return await rjky.get(`partners/${partnerId}/commissionStatements/${commissionStatementId}`).json();
    } catch (error) {
      commit(types.SET_ERROR, error);
      return [];
    }
  },

  async getCommissionStatements({ commit, getters }) {
    commit(types.UNSET_ERROR);
    try {
      const partnerId = getters.partner.id;
      if (!partnerId) {
        throw new Error('Missing partner');
      }
      return await rjky.get(`partners/${partnerId}/commissionStatements`).json();
    } catch (error) {
      commit(types.SET_ERROR, error);
      return [];
    }
  },

  async getPartner({ commit }, { partnerId, filters }) {
    const partner = await rjky.get(`partners/${partnerId}?${filters}`).json();

    tap(partner.users, (users) => {
      commit(`users/${usersTypes.SET_USERS}`, users || [], { root: true });
      delete partner.users;
    });

    tap(partner.partnerPlan, (plan) => {
      commit(types.SET_PLAN, plan || {});
      delete partner.partnerPlan;
    });

    commit(types.SET_PARTNER, partner);
  },

  async getProratedAmountForElite({ commit, getters }) {
    commit(types.UNSET_ERROR);
    const partnerId = getters.partner.id;
    if (!partnerId) {
      return null;
    }
    try {
      const { amount, renewalDate } = await rjky
        .get(`partners/${partnerId}/proratedAmountForElite`)
        .json();
      return { amount, renewalDate: new Date(renewalDate * 1000) };
    } catch (error) {
      const response = await error.response.json();
      commit(types.SET_ERROR, response.message);
      return null;
    }
  },

  async getProratedAmountForNewHighJump({ commit, getters }) {
    commit(types.UNSET_ERROR);
    const partnerId = getters.partner.id;
    if (!partnerId) {
      return null;
    }
    try {
      const { amount, renewalDate } = await rjky
        .get(`partners/${partnerId}/proratedAmountForNewHighJump`)
        .json();
      return { amount, renewalDate: new Date(renewalDate * 1000) };
    } catch (error) {
      const response = await error.response.json();
      commit(types.SET_ERROR, response.message);
      return null;
    }
  },

  async getProratedAmountForAddOn({ commit, getters }, code) {
    commit(types.UNSET_ERROR);
    const partnerId = getters.partner.id;
    if (!partnerId) {
      return null;
    }
    try {
      const { unitPrice, amount, renewalDate } = await rjky
        .get(`partners/${partnerId}/proratedAmountForAddOn?code=${encodeURIComponent(code)}`)
        .json();
      return { unitPrice, amount, renewalDate: new Date(renewalDate * 1000) };
    } catch (error) {
      return error.response.json();
    }
  },

  async getPartners({ commit }, filters) {
    const partners = await rjky.get(`partners?${filters}`).json();
    commit(types.SET_PARTNERS, partners.filter((partner) => partner.partnerPlan));
  },

  async loadData({ commit, getters, dispatch }, partnerId) {
    commit(types.UNSET_ERROR);
    let _partnerId = partnerId;
    if (!_partnerId) {
      _partnerId = getters.partner.id;
    }
    try {
      const filter = {
        include: [
          'partnerPlan',
          'mainContact',
          {
            relation: 'users',
            scope: {
              include: {
                relation: 'roleMappings',
                scope: {
                  include: ['role'],
                },
              },
            },
          },
          {
            relation: 'companies',
            scope: {
              include: [
                'mainContact',
                {
                  relation: 'plan',
                  scope: {
                    where: {
                      code: { inq: [HIGH_JUMP_CODE, LOW_JUMP_CODE] },
                    },
                  },
                },
                {
                  relation: 'locations',
                  scope: {
                    where: { pending: false },
                    include: [
                      'plan',
                      {
                        relation: 'locationReviewSites',
                        scope: {
                          include: ['reviewSite'],
                        },
                      },
                    ],
                  },
                },
                {
                  relation: 'users',
                  scope: {
                    include: {
                      relation: 'roleMappings',
                      scope: {
                        include: ['role'],
                      },
                    },
                  },
                },
              ],
            },
          },
        ],
      };

      const partner = await rjky
        .get(
          `partners/${_partnerId}?filter=${JSON.stringify(filter)}`,
        )
        .json();

      commit(types.SET_PARTNER, partner);
      commit(types.SET_PLAN, partner.partnerPlan);
      commit(`companies/${companyTypes.SET_COMPANIES}`, partner.companies.filter((company) => company.plan), { root: true });

      const { subscription = {} } = getters;
      if (subscription.status === 'past_due' && partner.id !== 36) { // 36 = RevenueJump Legacy Partner
        commit(types.SET_HAS_VALID_PAYMENT_METHOD, false);
      }

      dispatch('getArchivedCompanies', _partnerId);
      dispatch('getPaymentMethod', { partnerId: _partnerId });
      return partner;
    } catch (error) {
      commit(types.SET_ERROR, error);
      return null;
    }
  },

  async getArchivedCompanies({ commit }, partnerId) {
    commit(types.UNSET_ERROR);
    try {
      const filter = {
        where: {
          partnerId,
          active: false,
        },
        include: [
          {
            relation: 'plan',
            scope: {
              where: {
                code: { inq: [HIGH_JUMP_CODE, LOW_JUMP_CODE] },
              },
            },
          },
          {
            relation: 'mainContact',
            scope: {
              where: {
                active: false,
              },
            },
          },
          {
            relation: 'locations',
            scope: {
              where: {
                active: false,
              },
              include: ['plan'],
            },
          },
        ],
      };
      let companies = await rjky.get(`companies?filter=${JSON.stringify(filter)}`).json();
      companies = companies.filter((company) => company.plan);
      commit(types.SET_ARCHIVED_COMPANIES, companies);
      commit(`companies/${companyTypes.SET_ARCHIVED_COMPANIES}`, companies, { root: true });
    } catch (error) {
      commit(types.SET_ERROR, error);
    }
  },

  async updatePartnerDetails({ commit, getters }, payload) {
    commit(types.UNSET_ERROR);
    const partnerId = getters.partner.id;
    if (!partnerId) {
      return;
    }

    try {
      const response = await rjky
        .patch(`partners/${partnerId}`, {
          json: {
            ...payload,
            id: partnerId,
          },
        })
        .json();
      const {
        addressCountry,
        addressRegion,
        addressStreet,
        addressCity,
        addressZip,
        mainContactId,
        name,
        phone,
        website,
        fromName,
        fromEmail,
      } = response;
      commit(types.SET_PARTNER_DETAILS, {
        addressCountry,
        addressRegion,
        addressStreet,
        addressCity,
        addressZip,
        mainContactId,
        name,
        phone,
        website,
        fromName,
        fromEmail,
      });
    } catch (error) {
      const response = await error.response.json();
      commit(types.SET_ERROR, response.errorMessage);
    }
  },

  async getPaymentMethod({ commit }, { partnerId }) {
    commit(types.UNSET_ERROR);
    try {
      const { paymentMethod } = await rjky.get(`partners/${partnerId}/paymentMethod`).json();

      if (!paymentMethod) {
        commit(types.SET_HAS_VALID_PAYMENT_METHOD, false);
        return null;
      }

      const card = {
        brand: paymentMethod.brand,
        nameOnCard: paymentMethod.name,
        expiryMonth: paymentMethod.exp_month,
        expiryYear: paymentMethod.exp_year,
        last4: paymentMethod.last4,
      };

      // const now = new Date();
      // const expirationDate = new Date(card.expiryYear, card.expiryMonth - 1);
      // if (isAfter(now, expirationDate)) {
      //   commit(types.SET_HAS_VALID_PAYMENT_METHOD, false);
      //   return null;
      // }

      return card;
    } catch (error) {
      const response = await error.response.json();
      commit(types.SET_ERROR, response.message);
      return null;
    }
  },

  async updatePaymentMethod({ commit, getters }, payload) {
    commit(types.UNSET_ERROR);
    const partnerId = getters.partner.id;
    if (!partnerId) {
      return null;
    }
    try {
      const { paymentMethod } = await rjky
        .post(`partners/${partnerId}/paymentMethod`, {
          json: payload,
        })
        .json();
      commit(types.SET_HAS_VALID_PAYMENT_METHOD, true);
      return {
        brand: paymentMethod.brand,
        nameOnCard: paymentMethod.name,
        expiryMonth: paymentMethod.exp_month,
        expiryYear: paymentMethod.exp_year,
        last4: paymentMethod.last4,
      };
    } catch (error) {
      const response = await error.response.json();
      commit(types.SET_ERROR, response.message);
      return null;
    }
  },

  async upgradeToElite({ commit, getters }) {
    commit(types.UNSET_ERROR);
    const partnerId = getters.partner.id;
    if (!partnerId) {
      return null;
    }
    try {
      await rjky.get(`partners/${partnerId}/upgradeToElite`);
      return true;
    } catch (error) {
      const response = await error.response.json();
      commit(types.SET_ERROR, response.message);
      return null;
    }
  },

  async updateCommissionStatement(context, payload) {
    return rjky.patch(`commissionStatements/${payload.id}`, { json: payload });
  },

  async createDiscount(context, payload) {
    return rjky.post(`partners/${payload.partnerId}/createDiscount`, { json: payload }).json();
  },

  async deleteDiscount(context, payload) {
    return rjky.post(`partners/${payload.partnerId}/deleteDiscount`, { json: payload }).json();
  },

  async sendWelcomeEmail(context, { partnerId, companyId }) {
    try {
      const response = await rjky.get(`partners/${partnerId}/sendWelcomeEmail/${companyId}`).json();
      return response;
    } catch (error) {
      const response = await error.response.json();
      return response;
    }
  },

  async authenticateEmailDomain(context, payload) {
    return rjky.post(`partners/${payload.partnerId}/authenticateEmailDomain`, { json: payload }).json();
  },

  async getEmailDomains(context, partnerId) {
    return rjky.get(`partners/${partnerId}/getEmailDomains`).json();
  },

  async verifyEmailDomain(context, payload) {
    return rjky.post(`partners/${payload.partnerId}/verifyEmailDomain`, { json: payload }).json();
  },

  async deleteEmailDomain(context, payload) {
    return rjky.post(`partners/${payload.partnerId}/deleteEmailDomain`, { json: payload }).json();
  },
};
